import { FC } from 'react';
import IconProps from './IconProps';

const ExternalDynamicDataIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="2.66667" cy="2.5" rx="2.66667" ry="2" fill="currentColor" />
      <ellipse cx="13.3334" cy="9.5" rx="2.66667" ry="2" fill="currentColor" />
      <ellipse cx="13.3334" cy="14.5" rx="2.66667" ry="2" fill="currentColor" />
      <path d="M2.66669 3.5V7C2.66669 8.65685 4.00983 10 5.66669 10H12" stroke="currentColor" />
      <path d="M2.66669 3.5V11.5C2.66669 13.1569 4.00983 14.5 5.66669 14.5H12" stroke="currentColor" />
    </svg>
  );
};

export default ExternalDynamicDataIcon;
